/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'sass:map';
@use './app/styles/tables.scss';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.core();


$ppm-green-palette: (
    50: #e0f2f0,
    100: #b1ded9,
    200: #7ecac0,
    300: #4bb4a7,
    400: #22a494,
    500: #009483,
    600: #008776,
    700: #007766,
    800: #006757,
    A50: #fafafa,
    A100: #f6f6f6,
    A200: #f0f0f0,
    A300: #e3e3e3,
    A400: #c0c0c0,
    A500: #a1a1a1,
    A600: #787878,
    A700: #646464,
    A800: #454545,
    A900: #242424,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        A50: rgba(black, 0.87),
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A300: rgba(black, 0.87),
        A400: rgba(black, 0.87),
        A500: rgba(black, 0.87),
        A600: white,
        A700: white,
        A800: white,
        A900: white
    )
);



$my-primary: mat.define-palette($ppm-green-palette, 400);
$my-accent: mat.define-palette($ppm-green-palette, A700);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

$primary:  map.get(map.get(map.get($my-theme, color), primary), default);


@include mat.all-component-themes($my-theme);

html, body { height: 100%; }
body { width: 100%; margin: 0px; text-align: center; font-family: 'Roboto', sans-serif; }

h1 {color: $primary}
h2 {color: $primary; padding: 5px}

@include tables.selectable-table($my-theme);

#appContainer {
  max-width: 768px; text-align: left; margin: 0px auto;
}

.signature-dialog-container .mat-dialog-container {
  padding: 12px;
}

/*Fix mat button  ignoring contrast pallete*/
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: white;
 }
 
 .mat-mdc-unelevated-button.mat-accent {
   --mdc-filled-button-label-text-color: white;
 }
 
 .mat-mdc-raised-button.mat-primary {
   --mdc-protected-button-label-text-color: white;
 }
 
 .mat-mdc-raised-button.mat-accent {
   --mdc-protected-button-label-text-color: white;
 }