@use 'sass:map';
@mixin selectable-table($theme){
    .selectable-table tr.mat-mdc-row {
        cursor: pointer;
        }


    .selectable-table tr.mat-mdc-row:hover {
        background-color: map.get(map.get(map.get($theme, color), accent), default);

    }

    .selectable-table tr.mat-mdc-row:hover td.mat-mdc-cell {
        color: map.get(map.get(map.get($theme, color), accent), default-contrast);
    }
}
